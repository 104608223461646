/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    $.fn.accommodate = function (el, side) {
        var $container = $(this);
        var $el = (el instanceof jQuery) ? el : $(el);
        $(window).resize($.throttle(250, function () {
            $container.css('padding' + side.charAt(0).toUpperCase() + side.slice(1), $el.outerHeight() + 'px');
        })).trigger('resize');
    };
})(jQuery);

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {

                var $header = $('header.header');
                var $body = $('body');

                $('body').accommodate('.header', 'top');

                var getHeaderOffset = function () {
                    return {
                        top: -1 * $header.outerHeight(),
                        left: 0
                    };
                };

                $('.desktop-nav').localScroll({
                    offset: getHeaderOffset
                });

                $('.mobile-nav').localScroll({
                    offset: getHeaderOffset,
                    onBefore: function (e, el, target) {
                        $body.removeClass('is-active-mobile-nav');
                        $('.nav-trigger').removeClass('is-active');
                    }
                });

                $('.boats').localScroll({
                    offset: getHeaderOffset,
                    onBefore: function (e, el, target) {
                        console.log({event: e, element: el, target: target});
                        $('#input_1_8').val($(e.currentTarget).attr('data-boat-desired'));
                    }
                });

                function getRelatedNavigation(el) {
                    return $('.desktop-nav a[href=#' + $(el).attr('id') + ']');
                }

                var $sections = $('section:not(#closing), #contact');
                var $navLinks = $('.desktop-nav a');
                var waypoints = $sections.waypoint({
                   handler: function(direction){
                       console.log(this);
                       $navLinks.removeClass('active');
                       getRelatedNavigation(this.element).addClass('active');
                   },
                    offset: '50%'
                });
                $('.gallery-image').magnificPopup({
                    type: 'image',
                    mainClass: 'mfp-with-zoom', // this class is for CSS animation below
                    // delegate: '.slick-slide:not(.slick-cloned) a',
                    zoom: {
                        enabled: true, // By default it's false, so don't forget to enable it

                        duration: 300, // duration of the effect, in milliseconds
                        easing: 'ease-in-out', // CSS transition easing function

                        // The "opener" function should return the element from which popup will be zoomed in
                        // and to which popup will be scaled down
                        // By defailt it looks for an image tag:
                        opener: function (openerElement) {
                            // openerElement is the element on which popup was initialized, in this case its <a> tag
                            // you don't need to add "opener" option if this code matches your needs, it's defailt one.
                            return openerElement.is('.gallery-image') ? openerElement : openerElement.find('.gallery-image');
                        }
                    }
                });

                $('.boat-photo').magnificPopup({
                    type: 'image',
                    mainClass: 'mfp-with-zoom', // this class is for CSS animation below
                    // delegate: '.slick-slide:not(.slick-cloned) a',
                    zoom: {
                        enabled: true, // By default it's false, so don't forget to enable it

                        duration: 300, // duration of the effect, in milliseconds
                        easing: 'ease-in-out', // CSS transition easing function

                        // The "opener" function should return the element from which popup will be zoomed in
                        // and to which popup will be scaled down
                        // By defailt it looks for an image tag:
                        opener: function (openerElement) {
                            // openerElement is the element on which popup was initialized, in this case its <a> tag
                            // you don't need to add "opener" option if this code matches your needs, it's defailt one.
                            return openerElement.is('.boat-photo') ? openerElement : openerElement.find('.boat-photo');
                        }
                    }
                });


                // JavaScript to be fired on all pages
                $('.boats').slick({
                    slidesToShow: 3,
                    slideToScroll: 1,
                    arrows: true,
                    appendArrows: '.boats-nav',
                    dots: true,
                    appendDots: '.boats-nav',
                    responsive: [
                        {
                            breakpoint: 1100,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 750,
                            settings: {
                                slidesToShow: 1
                            }
                        },
                    ]
                });

                $('.gallery').slick({
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    autoplay: true,
                    arrows: true,
                    appendArrows: '.gallery-nav',
                    dots: true,
                    appendDots: '.gallery-nav',
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 1000,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 4
                            }
                        },
                        {
                            breakpoint: 750,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                    ]
                });


                $('.nav-trigger').click(function () {
                    $('body').toggleClass('is-active-mobile-nav');
                    $(this).toggleClass('is-active');
                });

                /* ------------------------------------------------------------------------
                 // MatchHeight
                 // ------------------------------------------------------------------------*/

                var matchHeightGroups = [];

                $('[data-match-height]').each(function () {
                    var group = $(this).attr('data-match-height');
                    if (matchHeightGroups.indexOf(group) === -1) {
                        matchHeightGroups[matchHeightGroups.length] = group;
                    }
                });

                $.each(matchHeightGroups, function (index, value) {
                    $('[data-match-height=' + value + ']').matchHeight();
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
